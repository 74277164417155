html, body, #root, .page, .App {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


#feature_img{
  width:80%;
  max-width: 500px;
}

.rows, .columns {
  width:100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
}

.rows {
  flex-direction:column;
}

.columns {
  flex-direction:row;
}

.box {
  flex-grow: 1
}

.o66 {
  flex-grow: 3;
}

.o75 {
  flex-grow: 4
}


.red {
  background:red;
}

.blue {
  background:green;
}

.green {
  background:blue;
}

.yellow {
  background:yellow;
}
